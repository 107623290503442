.theme .home {
  body {
    font-style: normal;
    line-height: 1.5;
    font-weight: 400;
    color: #232323;
    position: relative;
  }

  section,
  .container,
  .container-fluid {
    position: relative;
    word-wrap: break-word;
  }

  a.mbr-iconfont:hover {
    text-decoration: none;
  }

  .article .lead p,
  .article .lead ul,
  .article .lead ol,
  .article .lead pre,
  .article .lead blockquote {
    margin-bottom: 0;
  }

  a {
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
  }

  a, a:hover {
    text-decoration: none;
  }

  .mbr-section-title {
    font-style: normal;
    line-height: 1.3;
  }

  .mbr-section-subtitle {
    line-height: 1.3;
  }

  .mbr-text {
    font-style: normal;
    line-height: 1.7;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .display-1,
  .display-2,
  .display-4,
  .display-5,
  .display-7,
  span,
  p,
  a {
    line-height: 1;
    word-break: break-word;
    word-wrap: break-word;
    font-weight: 400;
  }

  b,
  strong {
    font-weight: bold;
  }

  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
    -webkit-transition-property: background-color, color;
    transition-property: background-color, color;
  }

  textarea[type="hidden"] {
    display: none;
  }

  section {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  section .mbr-background-video,
  section .mbr-background-video-preview {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  .hidden {
    visibility: hidden;
  }

  .mbr-z-index20 {
    z-index: 20;
  }

  /*! Base colors */
  .mbr-white {
    color: #ffffff;
  }

  .mbr-black {
    color: #111111;
  }

  .mbr-bg-white {
    background-color: #ffffff;
  }

  .mbr-bg-black {
    background-color: #000000;
  }

  /*! Text-aligns */
  .align-left {
    text-align: left;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

  /*! Font-weight  */
  .mbr-light {
    font-weight: 300;
  }

  .mbr-regular {
    font-weight: 400;
  }

  .mbr-semibold {
    font-weight: 500;
  }

  .mbr-bold {
    font-weight: 700;
  }

  /*! Media  */
  .media-content {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .media-container-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .media-container-row .media-size-item {
    width: 400px;
  }

  .media-container-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .media-container-column > * {
    width: 100%;
  }

  @media (min-width: 992px) {
    .media-container-row {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
  }

  figure {
    margin-bottom: 0;
    overflow: hidden;
  }

  figure[mbr-media-size] {
    -webkit-transition: width 0.1s;
    transition: width 0.1s;
  }

  img,
  iframe {
    display: block;
    width: 100%;
  }

  .card {
    background-color: transparent;
    border: none;
  }

  .card-box {
    width: 100%;
  }

  .card-img {
    text-align: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
  }

  .media {
    max-width: 100%;
    margin: 0 auto;
  }

  .mbr-figure {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .media-container > div {
    max-width: 100%;
  }

  .mbr-figure img,
  .card-img img {
    width: 100%;
  }

  @media (max-width: 991px) {
    .media-size-item {
      width: auto !important;
    }

    .media {
      width: auto;
    }

    .mbr-figure {
      width: 100% !important;
    }
  }

  /*! Buttons */
  .mbr-section-btn {
    margin-left: -0.6rem;
    margin-right: -0.6rem;
    font-size: 0;
  }

  .btn:not(.dropdown-toggle) {
    font-weight: 600;
    border-width: 1px;
    font-style: normal;
    margin: 0.6rem 0.6rem;
    white-space: normal;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    word-break: break-word;
  }

  .btn-sm {
    font-weight: 600;
    letter-spacing: 0px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .btn-md {
    font-weight: 600;
    letter-spacing: 0px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .btn-lg {
    font-weight: 600;
    letter-spacing: 0px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .btn-form {
    margin: 0;
  }

  .btn-form:hover {
    cursor: pointer;
  }

  nav .mbr-section-btn {
    margin-left: 0rem;
    margin-right: 0rem;
  }

  /*! Btn icon margin */
  .btn .mbr-iconfont,
  .btn.btn-sm .mbr-iconfont {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    cursor: pointer;
    margin-left: 0.5rem;
    vertical-align: sub;
  }

  .btn.btn-md .mbr-iconfont,
  .btn.btn-md .mbr-iconfont {
    margin-left: 0.8rem;
  }

  .mbr-regular {
    font-weight: 400;
  }

  .mbr-semibold {
    font-weight: 500;
  }

  .mbr-bold {
    font-weight: 700;
  }

  [type="submit"] {
    -webkit-appearance: none;
  }

  /*! Full-screen */
  .mbr-fullscreen .mbr-overlay {
    min-height: 100vh;
  }

  .mbr-fullscreen {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 100vh;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  /*! Map */
  .map {
    height: 25rem;
    position: relative;
  }

  .map iframe {
    width: 100%;
    height: 100%;
  }

  /*! Scroll to top arrow */
  .mbr-arrow-up {
    bottom: 25px;
    right: 90px;
    position: fixed;
    text-align: right;
    z-index: 5000;
    color: #ffffff;
    font-size: 22px;
  }

  .mbr-arrow-up a {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    height: 60px;
    width: 60px;
    border: 2px solid #fff;
    outline-style: none !important;
    position: relative;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-align: center;
  }

  .mbr-arrow-up a:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .mbr-arrow-up a i {
    line-height: 60px;
  }

  .mbr-arrow-up-icon {
    display: block;
    color: #fff;
  }

  .mbr-arrow-up-icon::before {
    content: "\203a";
    display: inline-block;
    font-family: serif;
    font-size: 22px;
    line-height: 1;
    font-style: normal;
    position: relative;
    top: 6px;
    left: -4px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  /*! Arrow Down */
  .mbr-arrow {
    position: absolute;
    bottom: 45px;
    left: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    background-color: rgba(80, 80, 80, 0.5);
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  @media (max-width: 767px) {
    .mbr-arrow {
      display: none;
    }
  }

  .mbr-arrow > a {
    display: inline-block;
    text-decoration: none;
    outline-style: none;
    -webkit-animation: arrowdown 1.7s ease-in-out infinite;
    animation: arrowdown 1.7s ease-in-out infinite;
    color: #ffffff;
  }

  .mbr-arrow > a > i {
    position: absolute;
    top: -2px;
    left: 15px;
    font-size: 2rem;
  }

  #scrollToTop a i::before {
    content: "";
    position: absolute;
    display: block;
    border-bottom: 2.5px solid #fff;
    border-left: 2.5px solid #fff;
    width: 27.8%;
    height: 27.8%;
    left: 50%;
    top: 50%;
    -webkit-transform: rotate(135deg);
    transform: translateY(-30%) translateX(-50%) rotate(135deg);
  }

  @keyframes arrowdown {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    50% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }

  @-webkit-keyframes arrowdown {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    50% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }

  @media (max-width: 500px) {
    .mbr-arrow-up {
      left: 50%;
      right: auto;
    }
  }

  /*Gradients animation*/
  @keyframes gradient-animation {
    from {
      background-position: 0% 100%;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }
    to {
      background-position: 100% 0%;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }
  }

  @-webkit-keyframes gradient-animation {
    from {
      background-position: 0% 100%;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }
    to {
      background-position: 100% 0%;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }
  }

  .bg-gradient {
    background-size: 200% 200%;
    animation: gradient-animation 5s infinite alternate;
    -webkit-animation: gradient-animation 5s infinite alternate;
  }

  .menu .navbar-brand {
    display: -webkit-flex;
  }

  .menu .navbar-brand span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
  }

  .menu .navbar-brand .navbar-caption-wrap {
    display: -webkit-flex;
  }

  .menu .navbar-brand .navbar-logo img {
    display: -webkit-flex;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .menu .navbar-toggleable-sm .navbar-nav {
      display: -webkit-box;
      display: -ms-flexbox;
    }
  }

  @media (max-width: 991px) {
    .menu .navbar-collapse {
      max-height: 93.5vh;
    }

    .menu .navbar-collapse.show {
      overflow: auto;
    }
  }

  @media (min-width: 992px) {
    .menu .navbar-nav.nav-dropdown {
      display: -webkit-flex;
    }

    .menu .navbar-toggleable-sm .navbar-collapse {
      display: -webkit-flex !important;
    }

    .menu .collapsed .navbar-collapse {
      max-height: 93.5vh;
    }

    .menu .collapsed .navbar-collapse.show {
      overflow: auto;
    }
  }

  @media (max-width: 767px) {
    .menu .navbar-collapse {
      max-height: 80vh;
    }
  }

  .nav-link .mbr-iconfont {
    margin-right: .5rem;
  }

  .navbar {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -webkit-align-items: center;
    -webkit-justify-content: space-between;
  }

  .navbar-collapse {
    -webkit-flex-basis: 100%;
    -webkit-flex-grow: 1;
    -webkit-align-items: center;
  }

  .nav-dropdown .link {
    padding: 0.667em 1.667em !important;
    margin: 0 !important;
  }

  .nav {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
  }

  .row {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
  }

  .justify-content-center {
    -webkit-justify-content: center;
  }

  .form-inline {
    display: -webkit-flex;
  }

  .card-wrapper {
    -webkit-flex: 1;
  }

  .carousel-control {
    z-index: 10;
    display: -webkit-flex;
  }

  .carousel-controls {
    display: -webkit-flex;
  }

  .media {
    display: -webkit-flex;
  }

  .form-group:focus {
    outline: none;
  }

  .jq-selectbox__select {
    padding: 7px 0;
    position: relative;
  }

  .jq-selectbox__dropdown {
    overflow: hidden;
    border-radius: 10px;
    position: absolute;
    top: 100%;
    left: 0 !important;
    width: 100% !important;
  }

  .jq-selectbox__trigger-arrow {
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .jq-selectbox li {
    padding: 1.07em 0.5em;
  }

  input[type="range"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .modal-dialog,
  .modal-content {
    height: 100%;
  }

  .modal-dialog .carousel-inner {
    height: calc(100vh - 1.75rem);
  }

  @media (max-width: 575px) {
    .modal-dialog .carousel-inner {
      height: calc(100vh - 1rem);
    }
  }

  .carousel-item {
    text-align: center;
  }

  .carousel-item img {
    margin: auto;
  }

  .navbar-toggler {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: flex-start;
    align-self: flex-start;
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .navbar-toggler:focus,
  .navbar-toggler:hover {
    text-decoration: none;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }

  .navbar-toggler-left {
    position: absolute;
    left: 1rem;
  }

  .navbar-toggler-right {
    position: absolute;
    right: 1rem;
  }

  .card-img {
    width: auto;
  }

  .menu .navbar.collapsed:not(.beta-menu) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .note-air-layout .dropup .dropdown-menu,
  .note-air-layout .navbar-fixed-bottom .dropdown .dropdown-menu {
    bottom: initial !important;
  }

  html,
  body {
    height: auto;
    min-height: 100vh;
  }

  .dropup .dropdown-toggle::after {
    display: none;
  }

  .form-asterisk {
    font-family: initial;
    position: absolute;
    top: -2px;
    font-weight: normal;
  }

  .form-control-label {
    position: relative;
    cursor: pointer;
    margin-bottom: 0.357em;
    padding: 0;
  }

  .alert {
    color: #ffffff;
    border-radius: 0;
    border: 0;
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 1.875rem;
    padding: 1.25rem;
    position: relative;
    text-align: center;
  }

  .alert.alert-form::after {
    background-color: inherit;
    bottom: -7px;
    content: "";
    display: block;
    height: 14px;
    left: 50%;
    margin-left: -7px;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 14px;
  }

  .form-control {
    background-clip: border-box;
    line-height: 1rem !important;
    height: auto;
    padding: 0.6rem 1.2rem;
    -webkit-transition: border-color 0.25s ease 0s;
    transition: border-color 0.25s ease 0s;
    border: 1px solid transparent !important;
    border-radius: 4px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
  }

  .form-active .form-control:invalid {
    border-color: red;
  }

  form .row {
    margin-left: -0.6rem;
    margin-right: -0.6rem;
  }

  form .row [class*="col"] {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  form .mbr-section-btn {
    margin: 0;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  form .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0.6rem 1.2rem;
    margin: 0;
  }

  form .form-check-input {
    margin-top: .5;
  }

  textarea.form-control {
    line-height: 1.5rem !important;
  }

  .form-group {
    margin-bottom: 1.2rem;
  }

  .form-control,
  form .btn {
    min-height: 48px;
  }

  .gdpr-block label span.textGDPR input[name='gdpr'] {
    top: 7px;
  }

  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  :focus {
    outline: none;
  }

  .mbr-overlay {
    opacity: 0.6;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    pointer-events: none;
  }

  blockquote {
    font-style: italic;
    padding: 3rem;
    font-size: 1.09rem;
    position: relative;
    border-left: 3px solid;
  }

  ul,
  ol,
  pre,
  blockquote {
    margin-bottom: 2.3125rem;
  }

  .mt-4 {
    margin-top: 2rem !important;
  }

  .mb-4 {
    margin-bottom: 2rem !important;
  }

  @media (min-width: 992px) {
    .container {
      padding-left: 16px;
      padding-right: 16px;
    }

    .row > [class*="col"] {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  @media (min-width: 768px) {
    .container-fluid {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .mbr-container {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  @media (max-width: 767px) {
    .mbr-container {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .card-wrapper,
  .item-wrapper {
    overflow: hidden;
  }

  .app-video-wrapper > img {
    opacity: 1;
  }

  .engine {
    position: absolute;
    text-indent: -2400px;
    text-align: center;
    padding: 0;
    top: 0;
    left: -2400px;
  }

  .demo-loader {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    opacity: 0.6;
  }

}
