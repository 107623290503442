@charset "UTF-8";
.theme .home {
  /*! Base colors */
  /*! Text-aligns */
  /*! Font-weight  */
  /*! Media  */
  /*! Buttons */
  /*! Btn icon margin */
  /*! Full-screen */
  /*! Map */
  /*! Scroll to top arrow */
  /*! Arrow Down */
  /*Gradients animation*/
}
.theme .home body {
  font-style: normal;
  line-height: 1.5;
  font-weight: 400;
  color: #232323;
  position: relative;
}
.theme .home section,
.theme .home .container,
.theme .home .container-fluid {
  position: relative;
  word-wrap: break-word;
}
.theme .home a.mbr-iconfont:hover {
  text-decoration: none;
}
.theme .home .article .lead p,
.theme .home .article .lead ul,
.theme .home .article .lead ol,
.theme .home .article .lead pre,
.theme .home .article .lead blockquote {
  margin-bottom: 0;
}
.theme .home a {
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
.theme .home a, .theme .home a:hover {
  text-decoration: none;
}
.theme .home .mbr-section-title {
  font-style: normal;
  line-height: 1.3;
}
.theme .home .mbr-section-subtitle {
  line-height: 1.3;
}
.theme .home .mbr-text {
  font-style: normal;
  line-height: 1.7;
}
.theme .home h1,
.theme .home h2,
.theme .home h3,
.theme .home h4,
.theme .home h5,
.theme .home h6,
.theme .home .display-1,
.theme .home .display-2,
.theme .home .display-4,
.theme .home .display-5,
.theme .home .display-7,
.theme .home span,
.theme .home p,
.theme .home a {
  line-height: 1;
  word-break: break-word;
  word-wrap: break-word;
  font-weight: 400;
}
.theme .home b,
.theme .home strong {
  font-weight: bold;
}
.theme .home input:-webkit-autofill, .theme .home input:-webkit-autofill:hover, .theme .home input:-webkit-autofill:focus, .theme .home input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
}
.theme .home textarea[type=hidden] {
  display: none;
}
.theme .home section {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.theme .home section .mbr-background-video,
.theme .home section .mbr-background-video-preview {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.theme .home .hidden {
  visibility: hidden;
}
.theme .home .mbr-z-index20 {
  z-index: 20;
}
.theme .home .mbr-white {
  color: #ffffff;
}
.theme .home .mbr-black {
  color: #111111;
}
.theme .home .mbr-bg-white {
  background-color: #ffffff;
}
.theme .home .mbr-bg-black {
  background-color: #000000;
}
.theme .home .align-left {
  text-align: left;
}
.theme .home .align-center {
  text-align: center;
}
.theme .home .align-right {
  text-align: right;
}
.theme .home .mbr-light {
  font-weight: 300;
}
.theme .home .mbr-regular {
  font-weight: 400;
}
.theme .home .mbr-semibold {
  font-weight: 500;
}
.theme .home .mbr-bold {
  font-weight: 700;
}
.theme .home .media-content {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.theme .home .media-container-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}
.theme .home .media-container-row .media-size-item {
  width: 400px;
}
.theme .home .media-container-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.theme .home .media-container-column > * {
  width: 100%;
}
@media (min-width: 992px) {
  .theme .home .media-container-row {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.theme .home figure {
  margin-bottom: 0;
  overflow: hidden;
}
.theme .home figure[mbr-media-size] {
  -webkit-transition: width 0.1s;
  transition: width 0.1s;
}
.theme .home img,
.theme .home iframe {
  display: block;
  width: 100%;
}
.theme .home .card {
  background-color: transparent;
  border: none;
}
.theme .home .card-box {
  width: 100%;
}
.theme .home .card-img {
  text-align: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
}
.theme .home .media {
  max-width: 100%;
  margin: 0 auto;
}
.theme .home .mbr-figure {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.theme .home .media-container > div {
  max-width: 100%;
}
.theme .home .mbr-figure img,
.theme .home .card-img img {
  width: 100%;
}
@media (max-width: 991px) {
  .theme .home .media-size-item {
    width: auto !important;
  }
  .theme .home .media {
    width: auto;
  }
  .theme .home .mbr-figure {
    width: 100% !important;
  }
}
.theme .home .mbr-section-btn {
  margin-left: -0.6rem;
  margin-right: -0.6rem;
  font-size: 0;
}
.theme .home .btn:not(.dropdown-toggle) {
  font-weight: 600;
  border-width: 1px;
  font-style: normal;
  margin: 0.6rem 0.6rem;
  white-space: normal;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  word-break: break-word;
}
.theme .home .btn-sm {
  font-weight: 600;
  letter-spacing: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.theme .home .btn-md {
  font-weight: 600;
  letter-spacing: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.theme .home .btn-lg {
  font-weight: 600;
  letter-spacing: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.theme .home .btn-form {
  margin: 0;
}
.theme .home .btn-form:hover {
  cursor: pointer;
}
.theme .home nav .mbr-section-btn {
  margin-left: 0rem;
  margin-right: 0rem;
}
.theme .home .btn .mbr-iconfont,
.theme .home .btn.btn-sm .mbr-iconfont {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  cursor: pointer;
  margin-left: 0.5rem;
  vertical-align: sub;
}
.theme .home .btn.btn-md .mbr-iconfont,
.theme .home .btn.btn-md .mbr-iconfont {
  margin-left: 0.8rem;
}
.theme .home .mbr-regular {
  font-weight: 400;
}
.theme .home .mbr-semibold {
  font-weight: 500;
}
.theme .home .mbr-bold {
  font-weight: 700;
}
.theme .home [type=submit] {
  -webkit-appearance: none;
}
.theme .home .mbr-fullscreen .mbr-overlay {
  min-height: 100vh;
}
.theme .home .mbr-fullscreen {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.theme .home .map {
  height: 25rem;
  position: relative;
}
.theme .home .map iframe {
  width: 100%;
  height: 100%;
}
.theme .home .mbr-arrow-up {
  bottom: 25px;
  right: 90px;
  position: fixed;
  text-align: right;
  z-index: 5000;
  color: #ffffff;
  font-size: 22px;
}
.theme .home .mbr-arrow-up a {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  height: 60px;
  width: 60px;
  border: 2px solid #fff;
  outline-style: none !important;
  position: relative;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;
}
.theme .home .mbr-arrow-up a:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.theme .home .mbr-arrow-up a i {
  line-height: 60px;
}
.theme .home .mbr-arrow-up-icon {
  display: block;
  color: #fff;
}
.theme .home .mbr-arrow-up-icon::before {
  content: "›";
  display: inline-block;
  font-family: serif;
  font-size: 22px;
  line-height: 1;
  font-style: normal;
  position: relative;
  top: 6px;
  left: -4px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.theme .home .mbr-arrow {
  position: absolute;
  bottom: 45px;
  left: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  background-color: rgba(80, 80, 80, 0.5);
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .theme .home .mbr-arrow {
    display: none;
  }
}
.theme .home .mbr-arrow > a {
  display: inline-block;
  text-decoration: none;
  outline-style: none;
  -webkit-animation: arrowdown 1.7s ease-in-out infinite;
  animation: arrowdown 1.7s ease-in-out infinite;
  color: #ffffff;
}
.theme .home .mbr-arrow > a > i {
  position: absolute;
  top: -2px;
  left: 15px;
  font-size: 2rem;
}
.theme .home #scrollToTop a i::before {
  content: "";
  position: absolute;
  display: block;
  border-bottom: 2.5px solid #fff;
  border-left: 2.5px solid #fff;
  width: 27.8%;
  height: 27.8%;
  left: 50%;
  top: 50%;
  -webkit-transform: rotate(135deg);
  transform: translateY(-30%) translateX(-50%) rotate(135deg);
}
@keyframes arrowdown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-webkit-keyframes arrowdown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@media (max-width: 500px) {
  .theme .home .mbr-arrow-up {
    left: 50%;
    right: auto;
  }
}
@keyframes gradient-animation {
  from {
    background-position: 0% 100%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  to {
    background-position: 100% 0%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
}
@-webkit-keyframes gradient-animation {
  from {
    background-position: 0% 100%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  to {
    background-position: 100% 0%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
}
.theme .home .bg-gradient {
  background-size: 200% 200%;
  animation: gradient-animation 5s infinite alternate;
  -webkit-animation: gradient-animation 5s infinite alternate;
}
.theme .home .menu .navbar-brand {
  display: -webkit-flex;
}
.theme .home .menu .navbar-brand span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
}
.theme .home .menu .navbar-brand .navbar-caption-wrap {
  display: -webkit-flex;
}
.theme .home .menu .navbar-brand .navbar-logo img {
  display: -webkit-flex;
}
@media (min-width: 768px) and (max-width: 991px) {
  .theme .home .menu .navbar-toggleable-sm .navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
  }
}
@media (max-width: 991px) {
  .theme .home .menu .navbar-collapse {
    max-height: 93.5vh;
  }
  .theme .home .menu .navbar-collapse.show {
    overflow: auto;
  }
}
@media (min-width: 992px) {
  .theme .home .menu .navbar-nav.nav-dropdown {
    display: -webkit-flex;
  }
  .theme .home .menu .navbar-toggleable-sm .navbar-collapse {
    display: -webkit-flex !important;
  }
  .theme .home .menu .collapsed .navbar-collapse {
    max-height: 93.5vh;
  }
  .theme .home .menu .collapsed .navbar-collapse.show {
    overflow: auto;
  }
}
@media (max-width: 767px) {
  .theme .home .menu .navbar-collapse {
    max-height: 80vh;
  }
}
.theme .home .nav-link .mbr-iconfont {
  margin-right: 0.5rem;
}
.theme .home .navbar {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-justify-content: space-between;
}
.theme .home .navbar-collapse {
  -webkit-flex-basis: 100%;
  -webkit-flex-grow: 1;
  -webkit-align-items: center;
}
.theme .home .nav-dropdown .link {
  padding: 0.667em 1.667em !important;
  margin: 0 !important;
}
.theme .home .nav {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
}
.theme .home .row {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
}
.theme .home .justify-content-center {
  -webkit-justify-content: center;
}
.theme .home .form-inline {
  display: -webkit-flex;
}
.theme .home .card-wrapper {
  -webkit-flex: 1;
}
.theme .home .carousel-control {
  z-index: 10;
  display: -webkit-flex;
}
.theme .home .carousel-controls {
  display: -webkit-flex;
}
.theme .home .media {
  display: -webkit-flex;
}
.theme .home .form-group:focus {
  outline: none;
}
.theme .home .jq-selectbox__select {
  padding: 7px 0;
  position: relative;
}
.theme .home .jq-selectbox__dropdown {
  overflow: hidden;
  border-radius: 10px;
  position: absolute;
  top: 100%;
  left: 0 !important;
  width: 100% !important;
}
.theme .home .jq-selectbox__trigger-arrow {
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.theme .home .jq-selectbox li {
  padding: 1.07em 0.5em;
}
.theme .home input[type=range] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.theme .home .modal-dialog,
.theme .home .modal-content {
  height: 100%;
}
.theme .home .modal-dialog .carousel-inner {
  height: calc(100vh - 1.75rem);
}
@media (max-width: 575px) {
  .theme .home .modal-dialog .carousel-inner {
    height: calc(100vh - 1rem);
  }
}
.theme .home .carousel-item {
  text-align: center;
}
.theme .home .carousel-item img {
  margin: auto;
}
.theme .home .navbar-toggler {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: flex-start;
  align-self: flex-start;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.theme .home .navbar-toggler:focus,
.theme .home .navbar-toggler:hover {
  text-decoration: none;
}
.theme .home .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
.theme .home .navbar-toggler-left {
  position: absolute;
  left: 1rem;
}
.theme .home .navbar-toggler-right {
  position: absolute;
  right: 1rem;
}
.theme .home .card-img {
  width: auto;
}
.theme .home .menu .navbar.collapsed:not(.beta-menu) {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.theme .home .carousel-item.active,
.theme .home .carousel-item-next,
.theme .home .carousel-item-prev {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.theme .home .note-air-layout .dropup .dropdown-menu,
.theme .home .note-air-layout .navbar-fixed-bottom .dropdown .dropdown-menu {
  bottom: initial !important;
}
.theme .home html,
.theme .home body {
  height: auto;
  min-height: 100vh;
}
.theme .home .dropup .dropdown-toggle::after {
  display: none;
}
.theme .home .form-asterisk {
  font-family: initial;
  position: absolute;
  top: -2px;
  font-weight: normal;
}
.theme .home .form-control-label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0.357em;
  padding: 0;
}
.theme .home .alert {
  color: #ffffff;
  border-radius: 0;
  border: 0;
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1.875rem;
  padding: 1.25rem;
  position: relative;
  text-align: center;
}
.theme .home .alert.alert-form::after {
  background-color: inherit;
  bottom: -7px;
  content: "";
  display: block;
  height: 14px;
  left: 50%;
  margin-left: -7px;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 14px;
}
.theme .home .form-control {
  background-clip: border-box;
  line-height: 1rem !important;
  height: auto;
  padding: 0.6rem 1.2rem;
  -webkit-transition: border-color 0.25s ease 0s;
  transition: border-color 0.25s ease 0s;
  border: 1px solid transparent !important;
  border-radius: 4px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
}
.theme .home .form-active .form-control:invalid {
  border-color: red;
}
.theme .home form .row {
  margin-left: -0.6rem;
  margin-right: -0.6rem;
}
.theme .home form .row [class*=col] {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}
.theme .home form .mbr-section-btn {
  margin: 0;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}
.theme .home form .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.6rem 1.2rem;
  margin: 0;
}
.theme .home form .form-check-input {
  margin-top: 0.5;
}
.theme .home textarea.form-control {
  line-height: 1.5rem !important;
}
.theme .home .form-group {
  margin-bottom: 1.2rem;
}
.theme .home .form-control,
.theme .home form .btn {
  min-height: 48px;
}
.theme .home .gdpr-block label span.textGDPR input[name=gdpr] {
  top: 7px;
}
.theme .home .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.theme .home :focus {
  outline: none;
}
.theme .home .mbr-overlay {
  opacity: 0.6;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.theme .home blockquote {
  font-style: italic;
  padding: 3rem;
  font-size: 1.09rem;
  position: relative;
  border-left: 3px solid;
}
.theme .home ul,
.theme .home ol,
.theme .home pre,
.theme .home blockquote {
  margin-bottom: 2.3125rem;
}
.theme .home .mt-4 {
  margin-top: 2rem !important;
}
.theme .home .mb-4 {
  margin-bottom: 2rem !important;
}
@media (min-width: 992px) {
  .theme .home .container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .theme .home .row > [class*=col] {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 768px) {
  .theme .home .container-fluid {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .theme .home .mbr-container {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 767px) {
  .theme .home .mbr-container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.theme .home .card-wrapper,
.theme .home .item-wrapper {
  overflow: hidden;
}
.theme .home .app-video-wrapper > img {
  opacity: 1;
}
.theme .home .engine {
  position: absolute;
  text-indent: -2400px;
  text-align: center;
  padding: 0;
  top: 0;
  left: -2400px;
}
.theme .home .demo-loader {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 0.6;
}

.theme .home {
  /* ---- Fluid typography for mobile devices ---- */
  /* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
  /* 100vw - current viewport width */
  /* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
  /* 0.65 - min scale variable, may vary */
  /* Buttons */
  /* Scroll to top button*/
  /* Forms */
  /* Don't ask, it works this way */
}
.theme .home .display-1 {
  font-size: 4.6rem;
  line-height: 1.1;
}
.theme .home .display-1 > .mbr-iconfont {
  font-size: 5.75rem;
}
.theme .home .display-2 {
  font-size: 3rem;
  line-height: 1.1;
}
.theme .home .display-2 > .mbr-iconfont {
  font-size: 3.75rem;
}
.theme .home .display-4 {
  font-size: 1.1rem;
  line-height: 1.5;
}
.theme .home .display-4 > .mbr-iconfont {
  font-size: 1.375rem;
}
.theme .home .display-5 {
  font-size: 2.2rem;
  line-height: 1.5;
}
.theme .home .display-5 > .mbr-iconfont {
  font-size: 2.75rem;
}
.theme .home .display-7 {
  font-size: 1.2rem;
  line-height: 1.5;
}
.theme .home .display-7 > .mbr-iconfont {
  font-size: 1.5rem;
}
@media (max-width: 992px) {
  .theme .home .display-1 {
    font-size: 3.68rem;
  }
}
@media (max-width: 768px) {
  .theme .home .display-1 {
    font-size: 3.22rem;
    font-size: calc(2.26rem + 2.34 * (100vw - 20rem) / 28);
    line-height: calc(1.1 * (2.26rem + 2.34 * (100vw - 20rem) / 28));
  }
  .theme .home .display-2 {
    font-size: 2.4rem;
    font-size: calc(1.7rem + 1.3 * (100vw - 20rem) / 28);
    line-height: calc(1.3 * (1.7rem + 1.3 * (100vw - 20rem) / 28));
  }
  .theme .home .display-4 {
    font-size: 0.88rem;
    font-size: calc(1.035rem + 0.065 * (100vw - 20rem) / 28);
    line-height: calc(1.4 * (1.035rem + 0.065 * (100vw - 20rem) / 28));
  }
  .theme .home .display-5 {
    font-size: 1.76rem;
    font-size: calc(1.42rem + 0.78 * (100vw - 20rem) / 28);
    line-height: calc(1.4 * (1.42rem + 0.78 * (100vw - 20rem) / 28));
  }
  .theme .home .display-7 {
    font-size: 0.96rem;
    font-size: calc(1.07rem + 0.13 * (100vw - 20rem) / 28);
    line-height: calc(1.4 * (1.07rem + 0.13 * (100vw - 20rem) / 28));
  }
}
.theme .home .btn {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.theme .home .btn-sm {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.theme .home .btn-md {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.theme .home .btn-lg {
  padding: 1rem 2.6rem;
  border-radius: 4px;
}
.theme .home .mbr-gallery-filter li.active .btn {
  background-color: #6592e6;
  border-color: #6592e6;
  color: #ffffff;
}
.theme .home .mbr-gallery-filter li.active .btn:focus {
  box-shadow: none;
}
.theme .home .scrollToTop_wraper {
  display: none;
}
.theme .home .form-control {
  font-size: 1.1rem !important;
  line-height: 1.5 !important;
  font-weight: 400 !important;
}
.theme .home .form-control > .mbr-iconfont {
  font-size: 1.375rem;
}
.theme .home .form-control:hover,
.theme .home .form-control:focus {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
  border-color: #6592e6 !important;
}
.theme .home .form-control:-webkit-input-placeholder {
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 400;
}
.theme .home .form-control:-webkit-input-placeholder > .mbr-iconfont {
  font-size: 1.375rem;
}
.theme .home blockquote {
  border-color: #6592e6;
}
.theme .home .jq-selectbox li:hover,
.theme .home .jq-selectbox li.selected {
  background-color: #6592e6;
  color: #ffffff;
}
.theme .home .jq-number__spin {
  transition: 0.25s ease;
}
.theme .home .jq-number__spin:hover {
  border-color: #6592e6;
}
.theme .home .jq-selectbox .jq-selectbox__trigger-arrow,
.theme .home .jq-number__spin.minus:after,
.theme .home .jq-number__spin.plus:after {
  transition: 0.4s;
  border-top-color: #353535;
  border-bottom-color: #353535;
}
.theme .home .jq-selectbox:hover .jq-selectbox__trigger-arrow,
.theme .home .jq-number__spin.minus:hover:after,
.theme .home .jq-number__spin.plus:hover:after {
  border-top-color: #6592e6;
  border-bottom-color: #6592e6;
}
.theme .home .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.theme .home .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.theme .home .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  color: #ffffff !important;
  background-color: #6592e6 !important;
  box-shadow: none !important;
}
.theme .home .xdsoft_datetimepicker .xdsoft_calendar td:hover,
.theme .home .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #000000 !important;
  background: #ff6666 !important;
  box-shadow: none !important;
}
.theme .home .lazy-bg {
  background-image: none !important;
}
.theme .home .lazy-placeholder:not(section),
.theme .home .lazy-none {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: auto;
}
.theme .home iframe.lazy-placeholder,
.theme .home .lazy-placeholder:after {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  background: transparent no-repeat center;
  background-size: contain;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' stroke='%236592e6' %3e%3cg fill='none' fill-rule='evenodd'%3e%3cg transform='translate(16 16)' stroke-width='2'%3e%3ccircle stroke-opacity='.5' cx='16' cy='16' r='16'/%3e%3cpath d='M32 16c0-9.94-8.06-16-16-16'%3e%3canimateTransform attributeName='transform' type='rotate' from='0 16 16' to='360 16 16' dur='1s' repeatCount='indefinite'/%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}
.theme .home section.lazy-placeholder:after {
  opacity: 0.5;
}
.theme .home body {
  overflow-x: hidden;
}
.theme .home a {
  transition: color 0.6s;
}
.theme .home .cid-s48MCQYojq .mbr-section-title {
  text-align: center;
}
.theme .home .cid-s48MCQYojq .mbr-text,
.theme .home .cid-s48MCQYojq .mbr-section-btn {
  text-align: center;
}
@media (max-width: 991px) {
  .theme .home .cid-s48MCQYojq .mbr-section-title,
.theme .home .cid-s48MCQYojq .mbr-section-btn,
.theme .home .cid-s48MCQYojq .mbr-text {
    text-align: center;
  }
}
.theme .home .cid-soU8PSECoL {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
@media (max-width: 991px) {
  .theme .home .cid-soU8PSECoL .image-wrapper {
    margin-bottom: 1rem;
  }
}
.theme .home .cid-soU8PSECoL img {
  width: 100%;
}
@media (min-width: 992px) {
  .theme .home .cid-soU8PSECoL .text-wrapper {
    padding: 2rem;
  }
}
.theme .home .cid-soU8QlAKKP {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
@media (max-width: 991px) {
  .theme .home .cid-soU8QlAKKP .image-wrapper {
    margin-bottom: 1rem;
  }
}
.theme .home .cid-soU8QlAKKP .row {
  flex-direction: row-reverse;
}
.theme .home .cid-soU8QlAKKP img {
  width: 100%;
}
@media (min-width: 992px) {
  .theme .home .cid-soU8QlAKKP .text-wrapper {
    padding: 2rem;
  }
}
.theme .home .cid-soU6QnGh9A {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media (min-width: 1500px) {
  .theme .home .cid-soU6QnGh9A .container {
    max-width: 1400px;
  }
}
.theme .home .cid-soU6QnGh9A .mbr-iconfont {
  display: block;
  font-size: 5rem;
  color: #6592e6;
  margin-bottom: 2rem;
}
.theme .home .cid-soU6QnGh9A .card-wrapper {
  margin-top: 3rem;
}
.theme .home .cid-soU6QnGh9A .row {
  justify-content: center;
}
.theme .home .cid-soU5dLgjOI {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: #ffffff;
}
.theme .home .cid-soU5dLgjOI .demo-map {
  height: 80vh;
  position: relative;
}
.theme .home .cid-soU5dLgjOI .demo-map iframe {
  height: 100%;
  width: 100%;
}
.theme .home .cid-soU5dLgjOI .demo-map [data-state-details] {
  color: #6b6763;
  height: 1.5em;
  margin-top: -0.75em;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}
.theme .home .cid-soU5dLgjOI .demo-map[data-state] {
  background: #e9e5dc;
}
.theme .home .cid-soU5dLgjOI .demo-map[data-state=loading] [data-state-details] {
  display: none;
}
.theme .home .cid-soU9jtw47v {
  padding-top: 17rem;
  padding-bottom: 17rem;
}
.theme .home .cid-soU9jtw47v .mbr-text,
.theme .home .cid-soU9jtw47v .mbr-section-btn {
  color: #232323;
}
.theme .home .cid-soU9jtw47v .card-title,
.theme .home .cid-soU9jtw47v .card-box {
  color: #ffffff;
}
.theme .home .cid-soU9jtw47v .mbr-text,
.theme .home .cid-soU9jtw47v .link-wrap {
  color: #ffffff;
}
.theme .home .cid-soU7JptK9v {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.theme .home .cid-soU7JptK9v .social-row {
  display: flex;
  flex-wrap: wrap;
}
.theme .home .cid-soU7JptK9v .soc-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  height: 2.5rem;
  width: 2.5rem;
}
@media (max-width: 767px) {
  .theme .home .cid-soU7JptK9v .row {
    text-align: center;
  }
  .theme .home .cid-soU7JptK9v .social-row {
    justify-content: center;
  }
}
.theme .home .cid-soU7JptK9v .list {
  list-style: none;
  padding-left: 0;
  color: #bbbbbb;
}
@media (max-width: 991px) {
  .theme .home .cid-soU7JptK9v .list {
    margin-bottom: 2rem;
  }
}
@media (min-width: 992px) {
  .theme .home .cid-soU7JptK9v .list {
    margin-bottom: 0rem;
  }
}
.theme .home .cid-soU7JptK9v .mbr-text {
  color: #bbbbbb;
}
.theme .home .cid-soU7JptK9v .mbr-iconfont {
  color: black;
}
.theme .home .cid-s48OLK6784 {
  z-index: 1000;
  width: 100%;
  position: relative;
  min-height: 60px;
}
.theme .home .cid-s48OLK6784 nav.navbar {
  position: fixed;
}
.theme .home .cid-s48OLK6784 .dropdown-item:before {
  font-family: Moririse2 !important;
  content: "\e966";
  display: inline-block;
  width: 0;
  position: absolute;
  left: 1rem;
  top: 0.5rem;
  margin-right: 0.5rem;
  line-height: 1;
  font-size: inherit;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
  transform: scale(0, 1);
  transition: all 0.25s ease-in-out;
}
.theme .home .cid-s48OLK6784 .dropdown-menu {
  padding: 0;
}
.theme .home .cid-s48OLK6784 .dropdown-item {
  border-bottom: 1px solid #e6e6e6;
}
.theme .home .cid-s48OLK6784 .dropdown-item:hover,
.theme .home .cid-s48OLK6784 .dropdown-item:focus {
  background: #6592e6 !important;
  color: white !important;
}
.theme .home .cid-s48OLK6784 .nav-dropdown .link {
  padding: 0 0.3em !important;
  margin: 0.667em 1em !important;
}
.theme .home .cid-s48OLK6784 .nav-dropdown .link.dropdown-toggle::after {
  margin-left: 0.5rem;
  margin-top: 0.2rem;
}
.theme .home .cid-s48OLK6784 .nav-link {
  position: relative;
}
.theme .home .cid-s48OLK6784 .container {
  display: flex;
  margin: auto;
}
.theme .home .cid-s48OLK6784 .iconfont-wrapper {
  color: #000000 !important;
  font-size: 1.5rem;
  padding-right: 0.5rem;
}
.theme .home .cid-s48OLK6784 .navbar-caption {
  padding-right: 4rem;
}
.theme .home .cid-s48OLK6784 .dropdown-menu,
.theme .home .cid-s48OLK6784 .navbar.opened {
  background: #6592e6 !important;
}
.theme .home .cid-s48OLK6784 .nav-item:focus,
.theme .home .cid-s48OLK6784 .nav-link:focus {
  outline: none;
}
.theme .home .cid-s48OLK6784 .dropdown .dropdown-menu .dropdown-item {
  width: auto;
  transition: all 0.25s ease-in-out;
}
.theme .home .cid-s48OLK6784 .dropdown .dropdown-menu .dropdown-item::after {
  right: 0.5rem;
}
.theme .home .cid-s48OLK6784 .dropdown .dropdown-menu .dropdown-item .mbr-iconfont {
  margin-left: -1.8rem;
  padding-right: 1rem;
  font-size: inherit;
}
.theme .home .cid-s48OLK6784 .dropdown .dropdown-menu .dropdown-item .mbr-iconfont:before {
  display: inline-block;
  transform: scale(1, 1);
  transition: all 0.25s ease-in-out;
}
.theme .home .cid-s48OLK6784 .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.theme .home .cid-s48OLK6784 .collapsed .dropdown .dropdown-menu .dropdown-item {
  padding: 0.235em 1.5em 0.235em 1.5em !important;
  transition: none;
  margin: 0 !important;
}
.theme .home .cid-s48OLK6784 .navbar {
  min-height: 77px;
  transition: all 0.3s;
  border-bottom: 1px solid transparent;
  background: #6592e6;
}
.theme .home .cid-s48OLK6784 .navbar:not(.navbar-short) {
  border-bottom: 1px solid #e6e6e6;
}
.theme .home .cid-s48OLK6784 .navbar.opened {
  transition: all 0.3s;
}
.theme .home .cid-s48OLK6784 .navbar .dropdown-item {
  padding: 0.5rem 1.8rem;
}
.theme .home .cid-s48OLK6784 .navbar .navbar-logo img {
  width: auto;
}
.theme .home .cid-s48OLK6784 .navbar .navbar-collapse {
  justify-content: flex-end;
  z-index: 1;
}
.theme .home .cid-s48OLK6784 .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.theme .home .cid-s48OLK6784 .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
@media (min-width: 992px) {
  .theme .home .cid-s48OLK6784 .navbar.collapsed.opened:not(.navbar-short) .navbar-collapse {
    max-height: calc(98.5vh - 4rem);
  }
}
.theme .home .cid-s48OLK6784 .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.theme .home .cid-s48OLK6784 .navbar.collapsed .dropdown-menu .dropdown-item:after {
  right: auto;
}
.theme .home .cid-s48OLK6784 .navbar.collapsed .dropdown-menu .dropdown-toggle[data-toggle=dropdown-submenu]:after {
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  border-top: 0.35em solid;
  border-right: 0.35em solid transparent;
  border-left: 0.35em solid transparent;
  border-bottom: 0;
  top: 55%;
}
.theme .home .cid-s48OLK6784 .navbar.collapsed ul.navbar-nav li {
  margin: auto;
}
.theme .home .cid-s48OLK6784 .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: center;
}
.theme .home .cid-s48OLK6784 .navbar.collapsed .icons-menu {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (max-width: 991px) {
  .theme .home .cid-s48OLK6784 .navbar .nav-item .nav-link::before {
    display: none;
  }
  .theme .home .cid-s48OLK6784 .navbar.opened .dropdown-menu {
    top: 0;
  }
  .theme .home .cid-s48OLK6784 .navbar .dropdown-menu .dropdown-submenu {
    left: 0 !important;
  }
  .theme .home .cid-s48OLK6784 .navbar .dropdown-menu .dropdown-item:after {
    right: auto;
  }
  .theme .home .cid-s48OLK6784 .navbar .dropdown-menu .dropdown-toggle[data-toggle=dropdown-submenu]:after {
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0;
    top: 55%;
  }
  .theme .home .cid-s48OLK6784 .navbar .navbar-logo img {
    height: 3.8rem !important;
  }
  .theme .home .cid-s48OLK6784 .navbar ul.navbar-nav li {
    margin: auto;
  }
  .theme .home .cid-s48OLK6784 .navbar .dropdown-menu .dropdown-item {
    padding: 0.25rem 1.5rem !important;
    text-align: center;
  }
  .theme .home .cid-s48OLK6784 .navbar .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    padding-right: 2rem;
  }
  .theme .home .cid-s48OLK6784 .navbar .navbar-toggler {
    flex-basis: auto;
  }
  .theme .home .cid-s48OLK6784 .navbar .icons-menu {
    padding-left: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.theme .home .cid-s48OLK6784 .navbar.navbar-short {
  min-height: 60px;
}
.theme .home .cid-s48OLK6784 .navbar.navbar-short .navbar-logo img {
  height: 3rem !important;
}
.theme .home .cid-s48OLK6784 .navbar.navbar-short .navbar-brand {
  padding: 0;
}
.theme .home .cid-s48OLK6784 .navbar-brand {
  flex-shrink: 0;
  align-items: center;
  margin-right: 0;
  padding: 0;
  transition: all 0.3s;
  word-break: break-word;
  z-index: 1;
}
.theme .home .cid-s48OLK6784 .navbar-brand .navbar-caption {
  line-height: inherit !important;
}
.theme .home .cid-s48OLK6784 .navbar-brand .navbar-logo a {
  outline: none;
}
.theme .home .cid-s48OLK6784 .dropdown-item.active,
.theme .home .cid-s48OLK6784 .dropdown-item:active {
  background-color: transparent;
}
.theme .home .cid-s48OLK6784 .navbar-expand-lg .navbar-nav .nav-link {
  padding: 0;
}
.theme .home .cid-s48OLK6784 .nav-dropdown .link.dropdown-toggle {
  margin-right: 1.667em;
}
.theme .home .cid-s48OLK6784 .nav-dropdown .link.dropdown-toggle[aria-expanded=true] {
  margin-right: 0;
  padding: 0.667em 1.667em;
}
.theme .home .cid-s48OLK6784 .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background: #6592e6;
}
.theme .home .cid-s48OLK6784 .navbar.navbar-expand-lg .dropdown .dropdown-menu .dropdown-submenu {
  margin: 0;
  left: 100%;
}
.theme .home .cid-s48OLK6784 .navbar .dropdown.open > .dropdown-menu {
  display: block;
}
.theme .home .cid-s48OLK6784 ul.navbar-nav {
  flex-wrap: wrap;
}
.theme .home .cid-s48OLK6784 .navbar-buttons {
  text-align: center;
  min-width: 170px;
}
.theme .home .cid-s48OLK6784 button.navbar-toggler {
  outline: none;
  width: 31px;
  height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
}
.theme .home .cid-s48OLK6784 button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: currentColor;
}
.theme .home .cid-s48OLK6784 button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}
.theme .home .cid-s48OLK6784 button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}
.theme .home .cid-s48OLK6784 button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}
.theme .home .cid-s48OLK6784 button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}
.theme .home .cid-s48OLK6784 nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.theme .home .cid-s48OLK6784 nav.opened .hamburger span:nth-child(2) {
  transform: rotate(45deg);
  transition: all 0.25s;
}
.theme .home .cid-s48OLK6784 nav.opened .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
  transition: all 0.25s;
}
.theme .home .cid-s48OLK6784 nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.theme .home .cid-s48OLK6784 .navbar-dropdown {
  padding: 0.5rem 1rem;
  position: fixed;
}
.theme .home .cid-s48OLK6784 a.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.theme .home .cid-s48OLK6784 .icons-menu {
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.3rem;
  text-align: center;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .theme .home .cid-s48OLK6784 .navbar {
    height: 77px;
  }
  .theme .home .cid-s48OLK6784 .navbar.opened {
    height: auto;
  }
  .theme .home .cid-s48OLK6784 .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
.theme .home .gradient-top {
  height: 30px;
  margin-top: -30px;
}
.theme .home .gradient-bottom {
  height: 30px;
}
.theme .home .gradient-bottom-container {
  z-index: 1;
  position: relative;
  margin-bottom: -30px;
}